import { useRouter } from "next/router";

function HomePage() {
  const router = useRouter();

  router.push("/topproducts");

  return <div></div>;
}

export default HomePage;
